import React from "react";
import Header from "./Header";
import "../styles/styles.scss";

const LayoutCustom = ({ children }) => {
    return (
        <div className="body">
            <Header />
            <div className="main">
                {children}
            </div>
        </div>
    );
};

export default LayoutCustom;
